import store from '@/store'
import axios from 'axios'
import { createRouter, createWebHistory } from 'vue-router'

const guard = async function (to, from, next) {
	if (store.getters.isRestored) {
		var tkopopcbH = store.state.isAuthenticated;
		if (tkopopcbH) {
			next()
			/*/axios.post('https://api.zaqecosystem.com/auth/token/refresh/', { refresh: store.state.refresh })
				.then(response => {
					if (response.status === 200 && tkopopcbH) {
						console.log(response.data.access)
						store.commit('setAToken', response.data.access)
						next()
					}
				}).catch(e => {
					store.commit('clear', '')
					sessionStorage.clear()
					localStorage.removeItem('PassSchema')
					if (localStorage.getItem('ZeS_Status') === 'OPERATIONSACTIVE') { localStorage.setItem("ZaqFH", "LOIPS") }
					next('/login')
				})/*/
		}
		else {
			window.location.href = '/login'
		}
	} else {
		// Wait for the state to be restored and checked again
		store.watch(
			state => state, // Modify this based on your state structure
			isRestored => {
				if (isRestored.isready) {
					var tkopopcbH = store.state.isAuthenticated;
					if (tkopopcbH) {
						next()
						/*/axios.post('https://api.zaqecosystem.com/auth/token/refresh/', { refresh: store.state.refresh })
							.then(response => {
								if (response.status === 200 && tkopopcbH) {
									console.log(response.data.access)
									store.commit('setAToken', response.data.access)
									next()
								}
							}).catch(e => {
								store.commit('clear', '')
								sessionStorage.clear()
								localStorage.removeItem('PassSchema')
								if (localStorage.getItem('ZeS_Status') === 'OPERATIONSACTIVE') { localStorage.setItem("ZaqFH", "LOIPS") }
								next('/login')
							})/*/
					}
					else {
						window.location.href = '/login'
					}
				}
				else {
					next('/login')
				}
			}
		);
	}
}

store.watch(state => state, n => {
	console.log('watched: ', store.state)
})
const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			name: 'Dashboard',
			component: () => import('../views/dash.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/signup',
			name: 'signup',
			component: () => import('../components/SignUP.vue'),

		},
		{
			path: '/sales/:id?',
			name: 'Sales',
			component: () => import('../views/sale.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			},
			props: (route) => {
			return {
				id: route.params.id ? route.params.id : null
			};
			},
		},
		{
			path: '/closure',
			name: 'Closure',
			component: () => import('../views/closure.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			},
			props: true
		},
		{
			path: '/payments',
			name: 'Payments',
			component: () => import('../views/payment.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			},
			props: true
		},
		{
			path: '/customers',
			name: 'Customers',
			component: () => import('../views/customern.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/customerss',
			name: 'Customerss',
			component: () => import('../views/customerp.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/tracking',
			name: 'Tracking',
			component: () => import('../views/trackingn.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/inventory',
			name: 'Inventory',
			component: () => import('../views/inventoryn.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/personalise',
			name: 'Personalise',
			component: () => import('../views/personalise.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/markdowns',
			name: 'Markdowns',
			component: () => import('../views/markdowns.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/users/details',
			name: 'usersdets',
			component: () => import('../views/employeedets.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/trackboard/user',
			name: 'trackboarddets',
			component: () => import('../views/trackboarddets.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/trackboard',
			name: 'Trackboard',
			component: () => import('../views/trackboard.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/requests',
			name: 'Requests',
			component: () => import('../views/requests.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/settings',
			name: 'settings',
			component: () => import('../views/Settings.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/customer',
			name: 'customer',
			component: () => import('../views/customer.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/audits',
			name: 'Audits',
			component: () => import('../views/audits.vue'),
			beforeEnter: (to, from, next) => {
				guard(to, from, next)
			}
		},
		{
			path: '/login',
			name: 'Login',
			component: () => import('../Login.vue'),
			meta: { Sidebar: false }
		},
		{
			path: '/resetPassword',
			name: 'pwd-reset',
			component: () => import('../components/resetPassword.vue'),
			meta: { Sidebar: false }
		},
		{ path: '/:pathMatch(.*)*', name: 'e404p', component: () => import('../components/404.vue') }
	],
})
export default router