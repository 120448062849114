<template>
	<Transition name="fade">
		<div v-if="isOnline" id="app" class="app">
			<!-- Content -->
			<div class="wlcm" v-if="isa && !statesetup">
				<img class="i1" :src="require('./assets/croom.png')" />
				<p class="t1">
					Welcome,<br><span>{{ usernm }}.</span>
				</p>
				<div class="load">
					<v-progress-circular indeterminate :size="22.5" :width="4">
					</v-progress-circular>
					<p class="t2">Downloading User Settings & Data...</p>
				</div>
			</div>
			<div v-else>
				<transition-group name="slide-fade">
					<div class="clall" key="notclear" @mouseenter="pausetimer()" @mouseleave="resumetimer()"
						v-if="shownotifications && !['e404p', 'signup', 'pwd-reset', 'support', 'Login'].includes($route.name)">
						<button class="clos" @click.stop="hideNots()"><span class="mdi mdi-close-circle"></span></button>
						<transition name="fade">
							<button v-if="PushNots.length > 0" class="clol" @click.stop="clearNots()">
								<p>{{ (PushNots.length + PushTask.length) > 1 ? 'Clear all' : 'Clear' }}</p>
							</button>
						</transition>
					</div>
					<div class="notific" key="notbody"
						v-if="shownotifications && !['e404p', 'signup', 'pwd-reset', 'support', 'Login'].includes($route.name)"
						@mouseenter="pausetimer()" @mouseleave="resumetimer()">
						<p class="notic" :style="(PushNots.length + PushTask.length)
							>=
							1 ? { 'justifyContent': 'flex-start' } : {}"><span class="mdi mdi-message-bulleted"
								style="font-size: 30px;margin-top:-11px;"></span><span
								v-if="(PushNots.length + PushTask.length) > 0" class="mdi mdi-circle" style="position: relative;
																																																	left: -7.5px;top: -21px;color: #F1441D;
																																																	font-size: 12px;"></span>&nbsp&nbsp&nbsp<span
								style="top: -10px;position: relative;font-family: sfpro;letter-spacing: 0px;">{{
									(PushNots.length + PushTask.length)
									>=
									1 ? (PushNots.length + PushTask.length) + ' notifications' : 'No Older Notifications'
								}}</span></p>
						<transition-group name="slide-fade" tag="ul">
							<button :key="item.id" v-for="(item, index) in PushTask" class="notifc"
								:style="{ 'z-index': 1000 - index }">
								<button class="closti" @click.stop="remtn(item.id)"><span
										class="mdi mdi-close-circle"></span></button>
								<p class="ti">{{ item.title }}</p>
								<img class="ov" v-if="item.type === 'imgu'" :src="item.pv" />
								<p class="bo">{{ item.body }}</p>
								<v-progress-linear :model-value="(item.progress / item.total) * 100" color="#0074ff"
									bg-color="#000000" bg-opacity="0.12" rounded :height="7.5"
									style="width: 85%;margin-left: 7.5%;margin-top: 0.5vw;margin-bottom: 0.1vw;"></v-progress-linear>
								<p class="boui" style="margin-bottom: 0.25vw;">Uploaded {{ item.progress }} of {{ item.total
								}}.
								</p>
								<p class="bos">Do Not Refresh/Close This Window.</p>
							</button>
							<button :key="item.val.messageId" v-for="item in PushNots" class="notifc"
								:style="{ 'z-index': item.priority }"
								@click="gotdl(PushNots.indexOf(item), item.val.data.dl)">
								<button class="closti" @click.stop="remove(PushNots.indexOf(item))"><span
										class="mdi mdi-close-circle"></span></button>
								<p class="ti">{{ item.val.notification.title }}</p>
								<p class="bo">{{ item.val.notification.body }}</p>
								<p v-if="item.val.notification.tinfo" class="bos">{{ item.val.notification.tinfo }}</p>
							</button>
						</transition-group>
					</div>
				</transition-group>
				<div class="callcont">
					<transition-group name="slide-fade">
						<div class="clcard" id="clcard" v-for="(item, index) in Object.values(clh)">
							<div v-if="item.ctype == 'i'">
								<v-progress-circular v-if="item.csog" indeterminate size="25" width="3" color="#ffffff20"
									class="ldta"></v-progress-circular>
								<img class="ia" :src="require('./assets/bpfp2.jpeg')" />
								<div class="t0">{{ item.who }}</div>
								<p class="t1" id="callfrom" v-if="calli">{{ item.name }}</p>
								<span class="material-icons gah">arrow_forward_ios</span>
								<div class="acbc" v-if="item.pding">
									<v-btn class="b1" @click="aic(item.id)" id="answerCallButton"
										style="color: #46e02e;"><span class="material-icons">call</span>&nbsp;Answer</v-btn>
									<v-btn class="b1" @click="ric(item.id)" id="rejectCallButton"
										style="color: #ff3a3a;"><span
											class="material-icons">call_end</span>&nbsp;Reject</v-btn>
									<v-btn class="b1" style="color: #fff458;"><span
											class="material-icons">phone_forwarded</span>&nbsp;By Pass</v-btn>
								</div>
								<div class="acbc2" v-else>
									<p class="a1">{{ typeof item.st == 'number' ? `${Math.floor((callTimeInSeconds -
										item.st)
										/ 60) < 10 ? '0' : ''}${Math.floor((callTimeInSeconds - item.st) /
											60)}:${((callTimeInSeconds - item.st) % 60) < 10 ? '0' : ''
										}${((callTimeInSeconds - item.st) % 60)}` : item.st }}</p>
											<v-progress-linear class="a2" color="#00000080" height="10"
												:model-value="50"></v-progress-linear>
											<span @click="muteibc(item.id)"
												:style="item.ismuted ? { 'background-color': '#00000099' } : { 'background-color': '#fff', 'color': '#000' }"
												class="material-icons a3">{{ item.ismuted ? 'mic_off' : 'mic' }}</span>
											<span @click="dic(item.id)" class="material-icons a3">call_end</span>
											<span class="material-icons a3"
												style="background-color: #ffd000;">phone_forwarded</span>
								</div>
							</div>
							<div v-else>
								<img class="ia" :src="require('./assets/bpfp2.jpeg')" />
								<div class="t0">{{ item.who }}</div>
								<p class="t1" id="callfrom">{{ item.name }}</p>
								<span class="material-icons gah">arrow_forward_ios</span>
								<div class="acbc2">
									<p class="a1">{{ typeof item.st == 'number' ? `${Math.floor((callTimeInSeconds -
										item.st)
										/ 60) < 10 ? '0' : ''}${Math.floor((callTimeInSeconds - item.st) /
											60)}:${((callTimeInSeconds - item.st) % 60) < 10 ? '0' : ''
										}${((callTimeInSeconds - item.st) % 60)}` : item.st }}</p>
											<v-progress-linear class="a2" color="#00000080" height="10"
												:model-value="50"></v-progress-linear>
											<span @click="muteobc(item.sid)"
												:style="item.ismuted ? { 'background-color': '#00000099' } : { 'background-color': '#fff', 'color': '#000' }"
												class="material-icons a3">{{ item.ismuted ? 'mic_off' : 'mic' }}</span>
											<span @click="dicobc(item.sid)" class="material-icons a3">call_end</span>
											<span class="material-icons a3"
												style="background-color: #ffd000;">phone_forwarded</span>
								</div>
							</div>
						</div>
					</transition-group>
				</div>
			</div>
			<CommandPalette id="cmplt" v-if="cps && !['e404p', 'signup', 'pwd-reset', 'support', 'Login'].includes($route.name)" @contextmenu.prevent="cps = false" />
			<Transition name="fade" mode="out-in">
				<router-view @call-my-method="ogcm" />
			</Transition>
		</div>
		<div v-else class="noint">
			<p class="intnaa"><span class="material-icons">signal_wifi_statusbar_connected_no_internet_4</span>No Internet
			</p>
			<img :src="require('./assets/crpldr.gif')" class="img1" />
			<p class="t1">Unavailable.</p>
			<p class="t2">Can't Tether the Racks Due To Unavailability Of The Link.<br>Systems' Going Stationary Until The
				Links Are Up For RackTethering.</p>
		</div>
	</Transition>
</template>
<script>
import axios from 'axios'
import router from './router';
import fb from './fb';
import firebase from 'firebase/compat/app';
import CryptoJS from 'crypto-js';
import { mapState } from 'vuex';
import { Device, Call } from '@twilio/voice-sdk'
import store from './store';
import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
import CommandPalette from './components/CommandPalette.vue'
export default {
	data() {
		return {
			cps: false,
			worker: null,
			startX: null,
			isOnline: true,
			twiins: null,
			scont: false,
			calli: null,
			csr: true,
			vcr: null,
			cpup: true,
			callTimeInSeconds: 0,
			cogt: null,
			clh: {},
			obc: false,
			obch: null,
			activityTimeout: null,
		}
	},
	async created() {
		this.startActivityTimeout();
		window.addEventListener('mousemove', this.resetActivityTimeout);
		window.addEventListener('keydown', this.resetActivityTimeout);
		this.getAccess()
		setInterval(() => {
			const currentTime = Math.floor(Date.now() / 1000);
			this.callTimeInSeconds = currentTime
		}, 1000)
		window.addEventListener('message', (event) => {
			if (event.origin !== 'http://localhost:8080') {
				return;
			}
			const message = event.data;
			if (message.type === 'navigateToSales') {
				const id = message.id;
				this.$router.push({ name: 'Sales', params: { id } });
			}
		});
		document.addEventListener('keydown', (event) => {
			const userAgent = window.navigator.userAgent;
			if (userAgent.indexOf('Mac') !== -1 && event.altKey && event.keyCode == 32) {
				this.cps = !this.cps;
			}
			else if ( userAgent.indexOf('Win') !== -1 && event.ctrlKey && event.keyCode == 32) {
				this.cps = !this.cps;
			}
		});
		document.addEventListener('click', (event) => {
			var element = document.getElementById('cmplt');
			if (element && !element.contains(event.target)) {
				this.cps = false;
			}
		});
	},
	components: { CommandPalette },
	computed: {
		...mapState({
			usernm: s => s.user, nots: s => s.nots, tasknots: s => s.tasknots,
			shownotifications: s => s.showNots, statesetup: s => s.statesetup, personalise: s => s.personalise,
			isa: s => s.isAuthenticated
		}),
		PushNots() {
			return this.nots
		},
		PushTask() {
			return Object.values(this.tasknots);
		},
		fcts() {
			const minutes = Math.floor(this.callTimeInSeconds / 60);
			const seconds = this.callTimeInSeconds % 60;
			return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
		}
	},
	watch: {
		'personalise.wallpaper': function (newWallpaper) {
			// Use require to resolve the image path
			document.body.style.backgroundImage = `url(${require(`./assets/${newWallpaper}.jpg`)})`;
		},
		calli(newActiveConnection, oldActiveConnection) {
			// This function will be called whenever activeConnection changes

			// Check if there's a new active connection
			if (newActiveConnection) {
				this.clh[newActiveConnection.outboundConnectionId] = {
					pding: true, rourl: '',
					name: newActiveConnection.parameters.From, who: 'UNKNOWN', st: null, ismuted: false,
					csog: true, ctype: 'i', id: newActiveConnection.outboundConnectionId
				}
				fb().db.collection('CustIndex').doc(`${newActiveConnection.parameters.From.substring(3)}`).get().then(i => {
					this.clh[newActiveConnection.outboundConnectionId].name = i.data().Shopname
					this.clh[newActiveConnection.outboundConnectionId].rourl = i.data().RCode
					this.clh[newActiveConnection.outboundConnectionId].csog = false
					this.clh[newActiveConnection.outboundConnectionId].who = 'CUSTOMER'
				})
				console.log('A new active connection is established.');
				newActiveConnection.on('disconnect', (connection) => {
					delete this.clh[newActiveConnection.outboundConnectionId]
				})
				newActiveConnection.on('cancel', (connection) => {
					delete this.clh[newActiveConnection.outboundConnectionId]
				})

				// Perform actions when a new connection is established
			} else {
				console.log('No active connection.');
				// Perform actions when the connection is null or cleared
			}
		},
		obch(newActiveConnection, oldActiveConnection) {
			// This function will be called whenever activeConnection changes

			// Check if there's a new active connection
			if (newActiveConnection) {
				console.log('A new active connection is established.');
				newActiveConnection.on('disconnect', (connection) => {
					console.log('Call has been disconnected.')
					delete this.clh[newActiveConnection.outboundConnectionId]
					this.obch = null; this.obc = false;
				})
				newActiveConnection.on('cancel', (connection) => {
					console.log('Call has been cancelled.')
					this.obch = null; this.obc = false;
				})
				newActiveConnection.on('ringing', (connection) => {
					this.clh[newActiveConnection.outboundConnectionId].st = 'Ringing...'
				})
				newActiveConnection.on('accept', (connection) => {
					this.clh[newActiveConnection.outboundConnectionId].st = Math.floor(Date.now() / 1000)
				})
				newActiveConnection.on('closed', (connection) => {
					newActiveConnection.disconnect()
					this.obch = null; this.obc = false;
					delete this.clh[newActiveConnection.outboundConnectionId]
				})
				newActiveConnection.on('reject', (connection) => {
					newActiveConnection.disconnect()
					this.obch = null; this.obc = false;
					delete this.clh[newActiveConnection.outboundConnectionId]
				})
				newActiveConnection.on('open', (connection) => {
					console.log('opened connections')
				})

				// Perform actions when a new connection is established
			} else {
				console.log('No active connection.');
				// Perform actions when the connection is null or cleared
			}
		},
	},
	async mounted() {
		document.body.style.backgroundImage = `url('../assets/${this.personalise.wallpaper}.jpg')`
		document.body.style.backgroundSize = `100vw 100vh`
		this.isOnline = navigator.onLine;
		window.addEventListener('offline', this.updateOnlineStatus);
		window.addEventListener('online', this.updateOnlineStatus);
		function decrypt(encryptedData) {
			// Set decryption key and initialization vector
			const key = CryptoJS.enc.Utf8.parse('Sxcag&7Dx_%rt38x')
			const iv = CryptoJS.enc.Utf8.parse('tgUTxzsg:43?902b')

			// Convert encrypted data from base64 to binary data
			const binaryData = CryptoJS.enc.Base64.parse(encryptedData)

			// Decrypt data
			const decryptedData = CryptoJS.AES.decrypt({
				ciphertext: binaryData
			}, key, {
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7
			})

			// Convert decrypted data from binary to UTF-8 string
			const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8)

			// Parse decrypted data as JSON
			const decryptedJson = JSON.parse(decryptedText)

			return decryptedJson
		}
		var audio = new Audio(require('./assets/notifsound.mp3'));
		this.$store.watch(
			(state) => state.isAuthenticated, // Modify this based on your state structure
			(newIsAuthenticated, oldIsAuthenticated) => {
				if (newIsAuthenticated) {
					setTimeout(async () => {
						if (!store.state.statesetup) {
							const dbName = 'zaqnet-ralph';
							const objectStoreName = 'crdp-v1';
							const cookieName = 'CDN.authClient.ZaqEcosystem.com';
							const storageKey = 'PassSchema';
							const encryptionToken = Cookie.get(cookieName)
							store.commit('sst', true)
							try {
								const db = await this.openDB(dbName, 1);
								const tx = db.transaction(objectStoreName, 'readonly');
								const store = tx.objectStore(objectStoreName);
								const data = await store.get(storageKey);
								tx.done;
								db.close();
								data.onsuccess = (event) => {
									const str = event.target.result;
									if (str) {
										try {
											const bytes = Crypto.AES.decrypt(str, encryptionToken);
											const restoredState = JSON.parse(bytes.toString(Crypto.enc.Utf8));
											console.log(restoredState);
											if (restoredState.statesetup) {
												window.location.reload()
											} else {
												setTimeout(async () => {
													const db1 = await this.openDB(dbName, 1);
													const tx1 = db1.transaction(objectStoreName, 'readonly');
													const store1 = tx1.objectStore(objectStoreName);
													const data1 = await store1.get(storageKey);
													tx1.done;
													db1.close();
													data1.onsuccess = (event) => {
														const str1 = event.target.result;
														if (str1) {
															try {
																const bytes1 = Crypto.AES.decrypt(str1, encryptionToken);
																const restoredState1 = JSON.parse(bytes1.toString(Crypto.enc.Utf8));
																console.log(restoredState1);
																if (restoredState1.statesetup) {
																	window.location.reload()
																}
															} catch (error) {
																console.log(error)
															}
														} else {
														}
													}
												}, 5000)
											}
										} catch (error) {
											console.log(error)
										}
									} else {
									}
								};

								data.onerror = (event) => {
									console.log(event)
								};
							} catch (error) {
								console.log(error)
							}
						}
					}, 7000);
					let isSwiping = true;
					document.addEventListener("mousedown", (event) => {
						if (event.clientX > (window.innerWidth - 10)) {
							isSwiping = true;
							this.startX = window.innerWidth - 5;
						}
					});
					document.addEventListener("mousemove", (event) => {
						if (isSwiping) {
							const currX = event.clientX;
							if (this.startX !== null && this.startX > currX && this.startX - currX > 100) {
								this.$store.commit('setShowNots', !this.shownotifications)
								isSwiping = false;
							}
						}
					});
					document.addEventListener("keydown", (event) => {
						if (event.altKey && event.keyCode == 78) {
							this.$store.commit('setShowNots', !this.shownotifications);
							return false;
						}
					})
					document.addEventListener("mouseup", (event) => {
						isSwiping = false;
						this.startX = null;
					});
					setTimeout(this.getAccess(), 450000)
					const userAgent = navigator.userAgent;
					// Check if the user agent contains "Mobile," "iPhone," or "iPad"
					const isMobileOrTablet = /Mobi|iPhone|iPad/i.test(userAgent);
					if (isMobileOrTablet) {
						// This is a mobile device (iPhone or iPad)
						// Skip the FCM initialization or handle it differently for mobile devices.
					} else {
						Notification.requestPermission().then((permission) => {
							if (permission === 'granted') {
								console.log('Permission granted');
								fb().fcm.getToken({ vapidKey: 'BByoMLQ0UM0yjc61PuWUBkuFrs5D58XuaFdgA7q0V9ck1Pdtq_XXfGAkEB7ICw5T87g_Gt6yr7Iqp0sTPVO1uGA' }).then((currentToken) => {
									if (currentToken) {
										// send the token to your server to store
										console.log(currentToken)
									} else {
									}
								}).catch((error) => {
								});

								fb().fcm.onMessage((payload) => {
									this.shownots()
									setTimeout(() => {
										this.playAudio(audio)
										console.log(payload)
										this.$store.commit('setNots', { val: payload, priority: this.PushNots.length + 1 })
									}, 300)
								});
							} else {
								console.log('Permission denied.');
							}
						}).catch((error) => {
							console.log('An error occurred while requesting permission. Error:', error);
						});
					}
					fb().db.collection("UserIndex")
						.onSnapshot(snap => {
							let tgs = [];
							let tgso = {};
							this.$store.commit('setUsersIndex', [])
							for (let i = 0; i < snap.docs.length; i++) {
								var driver = snap.docs[i].data();
								tgso[snap.docs[i].id] = snap.docs[i].data()
								tgs.push(driver)
							}
							this.$store.commit('setUsersIndex', tgs)
							this.$store.commit('setUserIndex', tgso)
						});
					fb().db.collection("UserIndex").doc(`${this.$store.state.me.zaqnetid}`).get().then(
						snap => {
							this.$store.commit('setPersonalSettings', snap.data().personalise)
						}
					);
					fb().db.collection("Requests").where('status', '==', 'pending').where('requested_from', '==', 'rajputanaindustries@gmail.com')
						.onSnapshot(snap => {
							let tgs = []
							this.$store.commit('setReqs', [])
							for (let i = 0; i < snap.docs.length; i++) {
								var driver = { val: snap.docs[i].data(), id: snap.docs[i].id };
								tgs.push(driver)
							}
							this.$store.commit('setReqs', tgs)
						});
					fb().db.collection("Requests").where('status', 'in', ['rejected', 'approved'])
						.where('requested_from', '==', 'rajputanaindustries@gmail.com')
						.where('date', '>=', firebase.firestore.Timestamp.fromDate(new Date(Date.now() - (60 * 60 * 24 * 1000 * 8))))
						.orderBy('date', 'desc')
						.onSnapshot(snap => {
							let tgs = []
							this.$store.commit('setReqshist', [])
							for (let i = 0; i < snap.docs.length; i++) {
								var driver = { val: snap.docs[i].data(), id: snap.docs[i].id };
								tgs.push(driver)
							}
							this.$store.commit('setReqshist', tgs)
						});
					fb().db.collection("ClosureReports").where('date', '>=', firebase.firestore.Timestamp.fromDate(new Date(Date.now() - (24 * 60 * 60 * 1000 * 8))))
						.onSnapshot(snap => {
							let tgs = []
							this.$store.commit('setCrpt', [])
							for (let i = 0; i < snap.docs.length; i++) {
								var driver = snap.docs[i].data()
								tgs.push(driver)
							}
							this.$store.commit('setCrpt', tgs)
						});
					fb().db.collection("InstitutionalData").doc('prolen').onSnapshot(snap => {
						this.$store.commit('setprolen', snap.data())
					});
					fb().db.collection('TxnsIndex').orderBy('date', 'desc').limit(5).onSnapshot(snap => {
						let tgs = {};
						for (let i = 0; i < snap.docs.length; i++) {
							tgs[snap.docs[i].id] = snap.docs[i].data();
						}
						this.$store.commit('setTxnsIndex', tgs)
					});
					fb().db.collection('RecycleBin').orderBy('date', 'desc').limit(10).onSnapshot(snap => {
						for (let i = 0; i < snap.docs.length; i++) {
							let didp = snap.docs[i].data()
							if (didp.type == 'sale_txn') {
								store.commit('remTxnFromIndex', didp.data.ReceiptNumber);
								didp.data.payment.pref.forEach(bd => {
									store.commit('remPymtFromIndex', bd.refid);
								})
								console.log('done delete')
							}
						}
					});
					fb().db.collection('TxnsIndex').orderBy('date', 'desc')
						.where('date', '>=', firebase.firestore.Timestamp.fromDate(new Date(Date.now() - (24 * 60 * 60 * 1000 * 8)))).get().then(snap => {
							let tgs = {};
							for (let i = 0; i < snap.docs.length; i++) {
								tgs[snap.docs[i].id] = snap.docs[i].data();
							}
							this.$store.commit('setTxnsIndex', tgs)
						});
					fb().db.collection('RecycleBin').orderBy('date', 'desc')
						.where('date', '>=', firebase.firestore.Timestamp.fromDate(new Date(Date.now() - (24 * 60 * 60 * 1000 * 15)))).get().then(snap => {
							for (let i = 0; i < snap.docs.length; i++) {
								let didp = snap.docs[i].data()
								if (didp.type == 'sale_txn') {
									store.commit('remTxnFromIndex', didp.data.ReceiptNumber);
									didp.data.payment.pref.forEach(bd => {
										store.commit('remPymtFromIndex', bd.refid);
									})
								}
							}
						});
					fb().db.collection('PymtIndex').orderBy('date', 'desc').limit(5).onSnapshot(snap => {
						let tgs = {};
						for (let i = 0; i < snap.docs.length; i++) {
							tgs[snap.docs[i].id] = snap.docs[i].data();
						}
						this.$store.commit('setPymtIndex', tgs)
					});
					fb().db.collection('InvtIndex').orderBy('date', 'desc').limit(5).onSnapshot(snap => {
						let tgs = {};
						for (let i = 0; i < snap.docs.length; i++) {
							tgs[snap.docs[i].id] = snap.docs[i].data();
						}
						this.$store.commit('setInvtIndex', tgs)
					});
					fb().db.collection('PymtIndex').orderBy('date', 'desc')
						.where('date', '>=', firebase.firestore.Timestamp
							.fromDate(new Date(Date.now() - (24 * 60 * 60 * 1000 * 8)))).get().then(snap => {
								let tgs = {};
								for (let i = 0; i < snap.docs.length; i++) {
									tgs[snap.docs[i].id] = snap.docs[i].data();
								}
								this.$store.commit('setPymtIndex', tgs)
							});
					fb().db.collection("ProdIndex")
						.onSnapshot(snap => {
							let inventory = [];
							let inventory2 = [];
							this.$store.commit('setProdIndex', [])
							this.$store.commit('setITAIndex', [])
							for (let i = 0; i < snap.docs.length; i++) {
								var driver = snap.docs[i].data();
								inventory.push(driver)
								inventory2.push({
									pid: driver.ProdPid, id: driver.ProdEan, name: driver.ProdName,
									img: driver.ProdImg, stock: driver.ProdStock, qty: 0, ptr: driver.ProdPtr, selected: false
								})
							}
							this.$store.commit('setProdIndex', inventory)
							this.$store.commit('setITAIndex', inventory2)
						});
					fb().db.collection("Clusters")
						.onSnapshot(snap => {
							let inventory = [];
							this.$store.commit('setClusters', [])
							for (let i = 0; i < snap.docs.length; i++) {
								var driver = snap.docs[i].data();
								inventory.push(driver)
							}
							this.$store.commit('setClusters', inventory)
						});
					fb().db.collection("InstitutionalData").doc("preferences")
						.onSnapshot(snap => {
							this.$store.commit('setInsDataFB', {})
							var driver = snap.data()
							this.$store.commit('setInsDataFB', driver)
						});
					fb().db.collection('CustIndex').orderBy('lasttxndate', 'desc').limit(10).get().then(snap => {
						let desserts = {};
						snap.forEach(doc => {
							desserts[doc.id] = doc.data();
						});
						if (this.$store.state.lvc === null) {
							this.$store.commit('setlvc', snap.docs[snap.docs.length - 1])
						}
						this.$store.commit('setCustIndex', desserts);
					});
					fb().db.collection('DeletedCustIndex').orderBy('date', 'desc').limit(20).get().then(snap => {
						let desserts = {};
						snap.forEach(doc => {
							desserts[doc.id] = doc.data();
						});
						this.$store.commit('setDelCustIndex', desserts);
					});
					fb().db.collection('Markdowns').onSnapshot(snap => {
						let desserts = [];
						this.$store.commit('setMarkdowns', [])
						for (let i = 0; i < snap.docs.length; i++) {
							desserts.push(snap.docs[i].data())
						}
						this.$store.commit('setMarkdowns', desserts)
					});
				}
			}
		);
		const vm = this;
		let device = new Device("eyJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIiwidHlwIjoiSldUIn0.eyJqdGkiOiJTSzM1MjgwZTEwM2YyZWNkMmIzOWFiN2VkMTIxNTg5OTI2LTE3MDE0MTUzMzEiLCJncmFudHMiOnsidm9pY2UiOnsiaW5jb21pbmciOnsiYWxsb3ciOnRydWV9LCJvdXRnb2luZyI6eyJhcHBsaWNhdGlvbl9zaWQiOiJBUGIwYzUzMTZlMjJmOTFiY2M3ODk0OGRiMjgzOWU0ZDI4In19LCJpZGVudGl0eSI6IisxNzEyMzcyMzYxOCJ9LCJpc3MiOiJTSzM1MjgwZTEwM2YyZWNkMmIzOWFiN2VkMTIxNTg5OTI2IiwiZXhwIjoxNzAxNDE4OTMxLCJuYmYiOjE3MDE0MTUzMzEsInN1YiI6IkFDMzcxZTYxMDRiNDNiZTI4YmZjZGM2ODcxMDUyMGYyMGMifQ.4GVTrvOCLdHr98lD_ikzUF_OEFiHjGeGwBXILKFto8c",
			{
				// Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
				// providing better audio quality in restrained network conditions. Opus will be default in 2.0.
				codecPreferences: ["opus", "pcmu"],
				// Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
				// but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
				// a second time and sending the tone twice. This will be default in 2.0.
				fakeLocalDTMF: true,
				// Use `enableRingingState` to enable the device to emit the `ringing`
				// state. The TwiML backend also needs to have the attribute
				// `answerOnBridge` also set to true in the `Dial` verb. This option
				// changes the behavior of the SDK to consider a call `ringing` starting
				// from the connection to the TwiML backend to when the recipient of
				// the `Dial` verb answers.
				enableRingingState: true,
				debug: true,
			});

		device.register()
		device.on('registered', device => {
			console.log('The device is ready to receive incoming calls.')
		});
		device.on("error", function (error) {
			console.log("Twilio.Device Error: " + error.message);
		});
		device.on("connect", function (conn) {
			console.log('Successfully established call ! ');
		});
		device.on("disconnect", function (conn) {
			console.log("Call ended.");
			vm.scont = false;

		});
		device.on("incoming", function (conn) {
			vm.calli = conn;
			console.log(vm.calli)
		});
	},
	beforeDestroy() {
		window.removeEventListener('offline', this.updateOnlineStatus);
		window.removeEventListener('online', this.updateOnlineStatus);
	},
	methods: {
		startActivityTimeout() {
			this.activityTimeout = setTimeout(() => {
				this.cps = false;
			}, 150000); // 60 seconds
		},
		resetActivityTimeout() {
			clearTimeout(this.activityTimeout);
			this.startActivityTimeout();
		},
		clearActivityTimeout() {
			clearTimeout(this.activityTimeout);
		},
		async openDB(name, version) {
			return new Promise((resolve, reject) => {
				const request = indexedDB.open(name, version);
				request.onupgradeneeded = (event) => {
					const db = event.target.result;
					if (!db.objectStoreNames.contains(objectStoreName)) {
						db.createObjectStore(objectStoreName);
					}
				};
				request.onsuccess = (event) => {
					const db = event.target.result;
					resolve(db);
				};
				request.onerror = (event) => {
					reject(event.target.error);
				};
			});
		},
		async ogcm(ops) {
			console.log(ops)
			if (!this.obc) {
				let call = new Device("eyJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIiwidHlwIjoiSldUIn0.eyJqdGkiOiJTSzM1MjgwZTEwM2YyZWNkMmIzOWFiN2VkMTIxNTg5OTI2LTE3MDE0MTUzMzEiLCJncmFudHMiOnsidm9pY2UiOnsiaW5jb21pbmciOnsiYWxsb3ciOnRydWV9LCJvdXRnb2luZyI6eyJhcHBsaWNhdGlvbl9zaWQiOiJBUGIwYzUzMTZlMjJmOTFiY2M3ODk0OGRiMjgzOWU0ZDI4In19LCJpZGVudGl0eSI6IisxNzEyMzcyMzYxOCJ9LCJpc3MiOiJTSzM1MjgwZTEwM2YyZWNkMmIzOWFiN2VkMTIxNTg5OTI2IiwiZXhwIjoxNzAxNDE4OTMxLCJuYmYiOjE3MDE0MTUzMzEsInN1YiI6IkFDMzcxZTYxMDRiNDNiZTI4YmZjZGM2ODcxMDUyMGYyMGMifQ.4GVTrvOCLdHr98lD_ikzUF_OEFiHjGeGwBXILKFto8c")
				this.obch = await call.connect(ops.prm)
				this.obc = true;
				this.clh[this.obch.outboundConnectionId] = { ...ops.data, ismuted: false, st: '', sid: this.obch.outboundConnectionId }
			}
		},
		aic(i) {
			if (this.calli) {
				this.calli.accept();
				this.clh[i].st = Math.floor(Date.now() / 1000);
				this.clh[i].pding = false;
			}
		},
		ric(i) {
			if (this.calli) {
				delete this.clh[i]
				this.calli.reject();
			}
		},
		dic(i) {
			if (this.calli) {
				delete this.clh[i]
				this.calli.disconnect();
			}
		},
		dicobc(i) {
			console.log('tto disconnect')
			if (this.obc) {
				this.obch.disconnect();
				this.obch = null;
				this.obc = false;
				delete this.clh[i]
			}
		},
		muteobc(i) {
			console.log('tto disconnect')
			if (this.obc) {
				this.obch.mute(!this.clh[i].ismuted);
				this.clh[i].ismuted = !this.clh[i].ismuted
			}
		},
		muteibc(i) {
			this.calli.mute(!this.clh[i].ismuted);
			this.clh[i].ismuted = !this.clh[i].ismuted
		},
		remtna() {
			this.$store.commit('remalltasknots', null);
		},
		remtn(id) {
			this.$store.commit('remtasknots', id);
		},
		updateOnlineStatus() {
			this.isOnline = navigator.onLine;
		},
		pausetimer() {
			clearTimeout(this.sntTimer);
		},
		resumetimer() {
			this.sntTimer = this.SetSNT(() => { this.$store.commit('setShowNots', false) }, 3000);
		},
		shownots() {
			clearTimeout(this.sntTimer);
			this.shownotifications = true;
			this.$store.commit('setShowNots', true)
			this.sntTimer = this.SetSNT(() => { this.$store.commit('setShowNots', false) }, 10000);
		},
		SetSNT(callback, delay) {
			return setTimeout(callback, delay);
		},

		playAudio(audio) {
			audio.pause();
			audio.currentTime = 0;
			audio.play();
		},
		remove(i) {
			this.$store.commit('remNots', i)
		},
		gotdl(i, r) {
			this.$store.commit('remNots', i)
			router.push(r)
		},
		clearNots() {
			this.$store.commit('clearNots', '')
			setTimeout(() => { this.$store.commit('setShowNots', !this.shownotifications) }, 1500)
		},
		hideNots() {
			this.$store.commit('setShowNots', !this.shownotifications)
		},
		decrypt(encryptedData) {
			// Set decryption key and initialization vector
			const key = CryptoJS.enc.Utf8.parse('Sxcag&7Dx_%rt38x')
			const iv = CryptoJS.enc.Utf8.parse('tgUTxzsg:43?902b')

			// Convert encrypted data from base64 to binary data
			const binaryData = CryptoJS.enc.Base64.parse(encryptedData)

			// Decrypt data
			const decryptedData = CryptoJS.AES.decrypt({
				ciphertext: binaryData
			}, key, {
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7
			})

			// Convert decrypted data from binary to UTF-8 string
			const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8)

			// Parse decrypted data as JSON
			const decryptedJson = JSON.parse(decryptedText)

			return decryptedJson
		},
		getAccess() {
			const retok = this.$store.state.refresh
			const requestOptions = {
				method: "POST",
				headers: {
					"Content-type": "application/json",
				},
				body: JSON.stringify({ refresh: retok })
			}
			if (this.$store.state.isAuthenticated) {
				fetch('https://api.zaqecosystem.com/auth/token/refresh/', requestOptions)
					.then(result => {
						if (result.ok) {
							result.json().then(r => {
								this.$store.commit('setAToken', r.access)
								axios.get('https://api.zaqecosystem.com/auth/institution/settings/get', { headers: { Authorization: `Bearer ${this.$store.state.access}` } }).then(i => {
									const cipher = i.data.data;
									const io = this.decrypt(cipher)
									this.$store.commit('mutateISettings', { keygroup: 'sharecenter_settings', key: 'send_messages', value: io.send_messages });
									this.$store.commit('mutateISettings', { keygroup: 'sharecenter_settings', key: 'send_emails', value: io.send_emails });
									this.$store.commit('mutateISettings', { keygroup: 'sharecenter_settings', key: 'custom_sender_id', value: io.custom_sender_id });
									this.$store.commit('mutateISettings', { keygroup: 'sharecenter_settings', key: 'custom_sender_address', value: io.custom_sender_address });
									this.$store.commit('mutateISettings', { keygroup: 'sharecenter_settings', key: 'use_tls', value: io.use_tls });
									this.$store.commit('mutateISettings', { keygroup: 'sharecenter_settings', key: 'sender_api_id', value: io.sender_api_id });
									this.$store.commit('mutateISettings', { keygroup: 'sharecenter_settings', key: 'sender_api_passkey', value: io.sender_api_passkey });
									this.$store.commit('mutateISettings', { keygroup: 'sharecenter_settings', key: 'sender_address', value: io.sender_address });
									this.$store.commit('mutateISettings', { keygroup: 'sharecenter_settings', key: 'sender_passkey', value: io.sender_passkey });
									this.$store.commit('mutateISettings', { keygroup: 'tax_settings', key: 'enabled_einvoicing', value: io.enabled_einvoicing });
									this.$store.commit('mutateISettings', { keygroup: 'tax_settings', key: 'gst_api_username', value: io.gst_api_username });
									this.$store.commit('mutateISettings', { keygroup: 'tax_settings', key: 'gst_api_password', value: io.gst_api_password });
									this.$store.commit('mutateISettings', { keygroup: 'tax_settings', key: 'gstin', value: io.gstin });
									this.$store.commit('mutateISettings', { keygroup: 'shards', key: 'balance', value: io.Shards });
									this.$store.commit('mutateISettings', { keygroup: 'carnet', key: 'sed', value: io.sub_renew_date });
									this.$store.commit('mutateISettings', { keygroup: 'carnet', key: 'isactive', value: io.sub_active });
									this.$store.commit('mutateISettings', { keygroup: 'carnet', key: 'id', value: io.CiD });
									this.$store.commit('mutateISettings', { keygroup: 'carnet', key: 'email', value: io.email });
									this.$store.commit('mutateISettings', { keygroup: 'carnet', key: 'name', value: io.Client_reg_name });
									this.$store.commit('mutateISettings', { keygroup: 'carnet', key: 'csk', value: io.secretkey });
									this.$store.commit('mutateISettings', { keygroup: 'sharecenter_settings', key: 'host_address', value: io.host_address });
									this.$store.commit('mutateISettings', { keygroup: 'sharecenter_settings', key: 'host_port', value: io.host_port });
									this.$store.commit('mutateISettings', { keygroup: 'privacy_settings', key: 'Share_zaqnet_analytics', value: io.Share_zaqnet_analytics });
									this.$store.commit('mutateISettings', { keygroup: 'privacy_settings', key: 'Personalised_ads', value: io.Personalised_ads });
									this.$store.commit('mutateISettings', { keygroup: 'privacy_settings', key: 'Ecosystem_data_grants', value: io.Ecosystem_data_grants });
									this.$store.commit('mutateISettings', { keygroup: 'subscription_which', key: 'id', value: io.subscription_plan_id });
									this.$store.commit('mutateISettings', { keygroup: 'subscription_which', key: 'name', value: io.subscription_plan_name });
									this.$store.commit('mutateISettings', { keygroup: 'subscription_which', key: 'sn', value: io.subscription_plan_sn });
									this.$store.commit('mutateISettings', { keygroup: 'subscription_which', key: 'desc', value: io.subscription_plan_desc });
									this.$store.commit('mutateISettings', { keygroup: 'subscription_which', key: 'price', value: io.subscription_plan_price });
									this.$store.commit('mutateISettings', { keygroup: 'subscription_which', key: 'icon_uri', value: io.subscription_plan_icon_uri });
									this.$store.commit('mutateISettings', { keygroup: 'subscription_which', key: 'theme', value: io.subscription_plan_theme });
									this.$store.commit('mutateISettings', { keygroup: 'subscription_which', key: 'updated_at', value: io.subscription_plan_icon_uri });
								}).finally(() => /*/console.log(this.$store.state.isettings.subscription_which)/*/ { })
							}).catch(i => {
								console.log(i)
							})
						}
						else {
							this.$store.commit('clear', '')
							let t = indexedDB.deleteDatabase('zaqnet-ralph')
							t.onsuccess = () => {
								sessionStorage.clear()
								localStorage.removeItem("PassSchema")
								if (localStorage.getItem('ZeS_Status') === 'OPERATIONSACTIVE') { localStorage.setItem("ZaqFH", "LOIPS") }
								window.location.href = '/login'
							}
						}
					})
			}
		}
	},
	destroyed() {
		this.clearActivityTimeout();
		window.removeEventListener('mousemove', this.resetActivityTimeout);
		window.removeEventListener('keydown', this.resetActivityTimeout);
	},
}

</script>
<script setup>
import { assertExpressionStatement } from '@babel/types';
import logoURLbns from 'file:///Users/itanishqsingh/Library/Mobile Documents/com~apple~CloudDocs/RDS Corp Backup/Pictures_CR/crpldr.gif';
document.addEventListener('keydown', function () {
	if (event.altKey && event.keyCode == 49) {
		router.push('/')
		return false;
	}
	else if (event.altKey && event.keyCode == 50) {
		router.push('/sales')
		return false;
	}
	else if (event.altKey && event.keyCode == 51) {
		router.push('/tracking')
		return false;
	}
	else if (event.altKey && event.keyCode == 52) {
		router.push('/inventory')
		return false;
	}
	else if (event.altKey && event.keyCode == 53) {
		router.push('/customerindex')
		return false;
	}
	else if (event.altKey && event.keyCode == 54) {
		router.push('/payments')
		return false;
	}
	else if (event.altKey && event.keyCode == 82) {
		router.push('/apps/requests')
		return false;
	}
	else if (event.altKey && event.keyCode == 77) {
		router.push('/markdowns')
		return false;
	}
	else if (event.altKey && event.keyCode == 83) {
		router.push('/settings')
		return false;
	}
}, false);
</script>
<style lang="scss" scoped>
.callcont {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 5vw;
	right: 2vw;
	gap: 0.9vw;

	.clcard {
		width: 30vw;
		min-height: 10vw;
		height: auto;
		border-radius: 1vw;
		background-color: #00000099;
		backdrop-filter: blur(1rem);
		z-index: 100;
		box-shadow: 9px 9px 29px 1px rgba(0, 0, 0, 0.3);
		border: 0.1vw solid #ffffff15;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.ldta {
			position: absolute;
			right: 0.5vw;
			top: 0.5vw;
		}

		.material-icons.gah {
			font-size: 1.5vw;
			position: absolute;
			right: 0.4vw;
			cursor: pointer;
			color: #ffffff50;
			padding: 0.4vw;
		}

		.material-icons.gah:hover {
			color: #ffffffcc;
		}

		.ia {
			top: 1vw;
			left: 1vw;
			position: absolute;
			height: 6.25vw;
			width: 6.25vw;
			border-radius: 10vw;
			background-color: #000000aa;
		}

		.t0 {
			color: #ffffffbb;
			position: relative;
			letter-spacing: 0.09vw;
			font-size: 0.85vw;
			margin-left: 9vw;
			margin-top: 1.9vw;
		}

		.t1 {
			color: #ffffffdd;
			position: relative;
			letter-spacing: -0.06vw;
			font-size: 1.3vw;
			line-height: 1.45vw;
			font-family: sfprobold;
			margin-left: 8.5vw;
			margin-top: 0vw;
			width: 62.5%;
		}

		.acbc {
			display: flex;
			position: relative;
			justify-content: center;
			gap: 0.5vw;
			margin-top: 1.9vw;

			.b1 {
				width: auto;
				background-color: transparent;
				box-shadow: none;
				text-transform: none;
				font-family: sfpro;
				letter-spacing: -0.01vw;
				font-size: 1.1vw;
				border-radius: 0vw;
				border-right: 0.09vw solid #ffffff30;
				height: 1.4vw;
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					font-size: 1vw;
					opacity: 0.8;
				}
			}

			.b1:last-child {
				border-right: none;
			}

			.b1:hover {
				background-color: transparent;
			}
		}

		.acbc2 {
			display: flex;
			position: relative;
			justify-content: flex-start;
			align-items: center;
			gap: 0.5vw;
			margin-top: 2.5vw;
			margin-left: 2vw;
			margin-bottom: 0.75vw;

			.a1 {
				width: auto;
				background-color: transparent;
				box-shadow: none;
				text-transform: none;
				font-family: sfpro;
				letter-spacing: -0.01vw;
				font-size: 1.1vw;
				border-radius: 0vw;
				height: 1.4vw;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ffffffdd;
				border-right: 0.1vw solid #ffffff50;
				padding-right: 0.5vw;
			}

			.a2 {
				width: 45%;
				height: 0.2vw;
			}

			.a3 {
				border-radius: 10vw;
				padding: 0.45vw;
				background-color: #ff0000;
				color: #fff;
			}
		}
	}
}


.wlcm {
	width: 100vw;
	height: 100vh;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding: 10vw;
	gap: 2vw;
	justify-content: center;
	align-items: center;

	.i1 {
		border-radius: 2.75vw;
		height: 15vw;
		width: 15vw;
		box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.26);
		border: 0.1vw solid #00000030;
	}

	.t1 {
		font-family: sfprobold;
		font-size: 5vw;
		letter-spacing: -0.4vw;
		color: #00000090;
		line-height: 7vw;
		text-align: center;

		span {
			font-family: inherit;
			color: #10171c;
			font-size: 8vw;
		}
	}

	.load {
		background-color: #00000010;
		border-radius: 10vw;
		width: auto;
		padding-left: 1vw;
		padding-right: 1vw;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5vw;
		padding-top: 0.3vw;
		padding-bottom: 0.3vw;

		.t2 {
			font-size: 1vw;
		}
	}
}

@font-face {
	font-family: sfpro;
	src: url('../assets/fonts/sfpro.4507b432.ttf');
}

@font-face {
	font-family: sfprobold;
	src: url('../assets/fonts/SFUI-TEXT-BOLD.91c61854.ttf');
}

@font-face {
	font-family: sfprosbold;
	src: url('../assets/fonts/sf-pro-display_semibold.woff2');
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.fade-fast-enter-active,
.fade-fast-leave-active {
	transition: opacity 0.2s ease;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
	opacity: 0;
}

.noint {
	width: 100%;
	height: 100%;
	background-color: #100C08;
	position: absolute;
	display: grid;

	.intnaa {
		justify-self: right;
		font-family: sfpro;
		font-size: 1.3vw;
		color: #000;
		position: absolute;
		background-color: #fff;
		padding-left: 1.25vw;
		padding-right: 1.25vw;
		padding-top: 0.5vw;
		padding-bottom: 0.5vw;
		border-radius: 10vw;
		margin-top: 2vw;
		margin-right: 2vw;

	}

	.img1 {
		width: 17.5vw;
		height: 17.5vw;
		justify-self: center;
		border-radius: 3.75vw;
		margin-top: 15vw;
	}

	.t1 {
		font-family: sfprobold;
		font-size: 6vw;
		letter-spacing: -0.4vw;
		color: #eee;
		justify-self: center;
		margin-top: -7vw;
	}

	.t2 {
		font-family: sfpro;
		font-size: 1.65vw;
		line-height: 1.75vw;
		color: #999;
		text-align: center;
		justify-self: center;
		margin-top: -8vw;
	}
}

.slide-fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(100%);
	opacity: 0;
}

.clall {
	position: absolute;
	right: 195px;
	height: 30px;
	z-index: 1001;
	background-color: transparent;
	justify-items: center;
	align-items: center;
	width: 145px;
	margin-top: 28px;
	transition: 0.2s;

	.clos {
		visibility: hidden;
		transition: 0.2s;
		width: 25px;
		height: 25px;
		border-radius: 100px;
		display: grid;
		align-items: center;
		z-index: 1;
		justify-content: center;
		left: 7.5px;
		overflow: hidden;
		background-color: #454545;
		border: 0px solid #454545;
		position: absolute;

		span {
			color: #ddd;
			position: relative;
			margin-top: -8.7px;
			font-size: 28px;
		}
	}

	.clol {
		visibility: hidden;
		width: auto;
		padding-left: 12.5px;
		padding-right: 12.5px;
		height: 25px;
		border-radius: 100px;
		align-items: center;
		justify-items: center;
		left: 37.5px;
		overflow: hidden;
		z-index: 1;
		background-color: transparent;
		transition: 0.2s;
		border: 0.99px solid #454545;
		background-color: #ddd;
		position: absolute;

		p {
			color: #454545;
			position: relative;
			margin-left: -1px;
			font-weight: 500;
			margin-top: -1px;
			font-size: 15px;
		}
	}

	.clos:active {
		transition: 0.3s;
		background-color: #303030;
		border: 0px solid #303030;

		span {
			color: #909090;
		}
	}

	.clol:active {
		border: 0.99px solid #303030;
		background-color: #909090;

		p {
			color: #303030;
		}
	}
}

.clall:hover {
	position: absolute;
	right: 195px;
	height: 30px;
	z-index: 1001;
	background-color: transparent;
	justify-items: center;
	align-items: center;
	width: 145px;
	margin-top: 28px;

	.clos {
		visibility: visible;
		transition: 0.2s;
		width: 25px;
		height: 25px;
		border-radius: 100px;
		align-items: center;
		display: grid;
		padding: 0;
		z-index: 1;
		justify-content: center;
		left: 7.5px;
		overflow: hidden;
		background-color: #454545;
		border: 0px solid #454545;
		position: absolute;

		span {
			color: #ddd;
			position: relative;
			font-size: 28px;
		}
	}

	.clol {
		visibility: visible;
		width: auto;
		padding-left: 12.5px;
		padding-right: 12.5px;
		height: 25px;
		border-radius: 100px;
		align-items: center;
		justify-items: center;
		left: 37.5px;
		overflow: hidden;
		z-index: 1;
		background-color: transparent;
		transition: 0.2s;
		border: 0.99px solid #454545;
		background-color: #ddd;
		position: absolute;

		p {
			color: #454545;
			position: relative;
			margin-left: -1px;
			font-weight: 500;
			margin-top: -1px;
			font-size: 15px;
		}
	}

	.clos:active {
		transition: 0.3s;
		background-color: #303030;
		border: 0px solid #303030;

		span {
			color: #909090;
		}
	}

	.clol:active {
		border: 0.99px solid #303030;
		background-color: #909090;

		p {
			color: #303030;
		}
	}
}

.notific {
	right: 40px;
	max-height: 90%;
	position: absolute;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	width: 290px;
	margin-top: 40px;
	padding-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
	justify-content: flex-start;
	align-items: flex-start;
	padding-bottom: 10px;
	border-radius: 30px;
	background-color: rgba(0, 0, 0, 0.05);
	backdrop-filter: blur(1.5rem);
	transition: 0.3s;
	overflow: auto;
}

.notic {
	font-family: sfpro;
	color: #fff;
	z-index: 10000;
	width: 100%;
	margin-top: 5px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.notifc {
	position: relative;
	transition: 0.3s;
	display: grid;
	width: 250px;
	box-shadow: 1px 1px 80px -20px #000000;
	min-height: 80px;
	max-height: auto;
	transition: 0.3s;
	background-color: #ccc;
	border: 1px solid #707070;
	border-radius: 18.5px;
	padding-bottom: 15px;
	margin-bottom: 10px;
	justify-self: center;

	.closti {
		width: 0px;
		height: 0px;
		border-radius: 100px;
		align-items: center;
		justify-content: center;
		display: grid;
		position: absolute;
		left: 0px;
		top: 0px;
		overflow: hidden;
		background-color: transparent;
		transition: 0.3s;

		span {
			color: #ccc;
			position: relative;
			margin-top: -7px;
			font-size: 0px;
		}
	}

	.ti {
		font-family: sfprobold;
		letter-spacing: -0.045vw;
		font-size: 1.05vw;
		position: relative;
		margin-left: 14px;
		margin-top: 7px;
		font-weight: 600;
		text-align: left;
		color: #000000db;
	}

	.bo {
		font-family: sfpro;
		font-size: 0.795vw;
		color: #000000c0;
		position: relative;
		margin-left: 14px;
		margin-top: 2.5px;
		text-align: left;
		line-height: 13.5px;
		width: 85%;
	}

	.ov {
		position: relative;
		width: 10vw;
		border-radius: 0.5vw;
		box-shadow: 2px 11.5px 34px -8px rgba(0, 0, 0, 0.5);
		height: 10vw;
		justify-self: center;
		margin-top: 0.75vw;
		margin-bottom: 0.75vw;
		background-color: #000;
	}

	.bos {
		font-family: sfpro;
		font-size: 0.65vw;
		color: #00000080;
		position: relative;
		margin-left: 14px;
		margin-top: 2.5px;
		text-align: left;
		line-height: 13.5px;
		width: 85%;
	}

	.boui {
		font-family: sfpro;
		font-size: 0.7vw;
		color: #000000c0;
		position: relative;
		margin-left: 10%;
		margin-top: 2.5px;
		text-align: left;
		line-height: 13.5px;
		width: 85%;
	}
}

.notifc:hover {
	transition: 0.3s;

	.closti {
		transition: 0.3s;
		width: 22.2px;
		height: 22.2px;
		border-radius: 100px;
		position: absolute;
		left: -7.5px;
		top: -7.5px;
		overflow: hidden;
		background-color: #454545;

		span {
			color: #ccc;
			position: relative;
			font-size: 24px;
		}
	}
}

:root {
	--primary: #ffa971;
	--primary-alt: #fb6400;
	--grey: #64748b;
	--dark: #ffffff;
	--dark-alt: #360000;
	--light: #f0efef;
	--menu-color: #000000;
	--menu-hover: #f0efef;
	--sidebar-width: 300px;

	@font-face {
		font-family: sfpro;
		src: url('../assets/fonts/sfpro.4507b432.ttf');
	}

	@font-face {
		font-family: sfprobold;
		src: url('../assets/fonts/SFUI-TEXT-BOLD.91c61854.ttf');
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'sfpro', 'sfprobold';
}

body {
	background-image: url('/Volumes/RDS-Corp/ControlRoom/rrs.network/assets/15.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	font-family: sfpro;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}


button {
	cursor: pointer;
	appearance: none;
	border: none;
	outline: none;
	background: none;
}

.app {
	display: flex;

	main {
		flex: 1 1 0;
		padding: 2rem;

		@media (max-width: 1024px) {
			padding-left: 6rem;
		}
	}
}
</style>