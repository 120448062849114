import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/messaging'
import 'firebase/compat/storage'
import store from './store';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('firebase-messaging-sw.js')
    .then(reg => {
      console.log(`Service Worker Registration (Scope: ${reg.scope})`);
    })
    .catch(error => {
      const msg = `Service Worker Error (${error})`;
      console.error(msg);
    });
} else {
  // happens when the app isn't served over HTTPS or if the browser doesn't support service workers
  console.warn('Service Worker not available');
}
let db = null;
let fcm = null;
let sto = null;
store.watch(
  state => state, // Modify this based on your state structure
  isRestored => {
  if (isRestored.isAuthenticated) {
    var ZaqEcoEnvdbAPIKEY = store.state.ZaqEcoEnvdbAPIKEY
    var ZaqEcoEnvdbDomain = store.state.ZaqEcoEnvdbDomain
    var ZaqEcoEnvdbaseURL = store.state.ZaqEcoEnvdbaseURL
    var ZaqEcoEnvdbPROJiD = store.state.ZaqEcoEnvdbPROJiD
    var ZaqEcoEnvdbSTObkt = store.state.ZaqEcoEnvdbSTObkt
    var ZaqEcoEnvdbMsgSiD = store.state.ZaqEcoEnvdbMsgSiD
    var ZaqEcoEnvdbAPPliD = store.state.ZaqEcoEnvdbAPPliD
    var ZaqEcoEnvdbmeaSiD = store.state.ZaqEcoEnvdbmeaSiD
    const ZNDynaConfig = {
      apiKey: ZaqEcoEnvdbAPIKEY,
      authDomain: ZaqEcoEnvdbDomain,
      databaseURL: ZaqEcoEnvdbaseURL,
      projectId: ZaqEcoEnvdbPROJiD,
      storageBucket: ZaqEcoEnvdbSTObkt,
      messagingSenderId: ZaqEcoEnvdbMsgSiD,
      appId: ZaqEcoEnvdbAPPliD,
      measurementId: ZaqEcoEnvdbmeaSiD
    };
    firebase.initializeApp(ZNDynaConfig)
    db = firebase.firestore()
    fcm =firebase.messaging()
    sto = firebase.storage()
    db.settings({ timestampsInSnapshots: true }) 
    store.commit('fisr',true)
  } else {
    db = null;
    fcm =  null;
    sto = null;
    store.commit('fisr',false)
  }
})
export default function getFirebaseInstances() {
  return { db, fcm, sto };
}