import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import 'material-icons/iconfont/material-icons.css'
import 'vuelidate'
import axios from 'axios'
import VueApexCharts from 'vue3-apexcharts'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { playButtonClickSound, playButtonClickSound2 } from './sounds/click1';
import '@mdi/font/css/materialdesignicons.css';
import './global_style.css';
loadFonts()
axios.defaults.baseURL = 'http://localhost:8000'

const app = createApp(App);

app.directive('play-sound', {
  beforeMount(el) {
    el.addEventListener('click', playButtonClickSound);
  },
  beforeUnmount(el) {
    el.removeEventListener('click', playButtonClickSound);
  }
});
app.directive('play-sound2', {
  beforeMount(el) {
    el.addEventListener('click', playButtonClickSound2);
  },
  beforeUnmount(el) {
    el.removeEventListener('click', playButtonClickSound2);
  }
});
app.use(vuetify)
    .use(router)
    .use(store, axios)
    .use(VueGoogleMaps, {
      load: {
        key: 'AIzaSyCoKbAKr4ScBQUFJXlZgrKviKV6hJdnAWo',
        libraries: ['places','visualization'],
      }
    })
    .use(VueApexCharts)
    .mount('#app')
