<template>
    <div class="main" @click="ch()" ref="mainComponent">
        <div class="topd">
            <VTextField class="tf1" type="text" variant='plain' prepend-icon="mdi-magnify" placeholder="Magic Search"
                clearable="true" clear-icon="mdi-close-circle" density='compact' single-line="true" id="msfit"
                v-model.trim="msf"></VTextField>
        </div>
        <div class="subd">
            <div class="it1" ref="infotext1">
                <p>Search anything & everything... Just hit the keys and see the magic.</p>
            </div>
            <div class="d3" v-for="it in Object.keys(msfd.d3)" v-if="Object.values(msfd.d3).some((i) => i.length > 0)">
                <p class="t0" v-if="msfd.d3[it].length > 0">{{ it }}</p>
                <div class="sd2" v-if="msfd.d3[it].length > 0" />
                <div class="sd3" v-if="msfd.d3[it].length > 0">
                    <div class="d" @click="item.func" v-if="msfd.d3[it].length > 0" v-for="(item, index) in msfd.d3[it]"
                        :key="index">
                        <span class="material-icons iconm">account_circle</span>
                        <p class="name">{{ item.name }}</p>
                        <span v-if="item.external" class="material-icons icone">north_east</span>
                    </div>
                </div>
            </div>
            <div class="d4" v-for="it in Object.keys(msfd.d4)" v-if="Object.values(msfd.d4).some((i) => i.length > 0)">
                <p class="t0">{{ it }}</p>
                <div class="sd2" />
                <div class="sd3">
                    <div class="d" @click="item.func" v-for="(item, index) in msfd.d4[it]" :key="index">
                        <img :src="require('../assets/document.svg')" />
                        <div class="app" v-play-sound>
                            <span class="material-icons icon" :style="item.style">{{ item.icon }}</span>
                        </div>
                        <div class="infd">
                            <p class="t1">{{ item.id }}</p>
                            <p class="t2">{{ item.ClientData.name }}</p>
                            <p class="t3">{{ new Date(item.date.seconds * 1000).toLocaleString('en-IN') }}</p>
                        </div>
                        <span v-if="item.sic" class="material-icons icone">language</span>
                    </div>
                </div>
            </div>
            <div class="d1" v-for="it in Object.keys(msfd.d1)" v-if="Object.values(msfd.d1).some((i) => i.length > 0)">
                <p class="t0">{{ it }}</p>
                <div class="sd2" />
                <div class="sd3">
                    <div class="app" @click="route(item.route)" v-for="(item, index) in msfd.d1[it]" :key="index" v-play-sound>
                        <span class="material-icons icon" :style="item.style">{{ item.icon }}</span>
                        <p class="name">{{ item.name }}</p>
                    </div>
                </div>
            </div>
            <div class="d2" v-for="it in Object.keys(msfd.d2)" v-if="Object.values(msfd.d2).some((i) => i.length > 0)">
                <p class="t0">{{ it }}</p>
                <div class="sd2" />
                <div class="sd3">
                    <div class="d" v-for="(item, index) in msfd.d2[it]" :key="index">
                        <div class="app" v-play-sound>
                            <span class="material-icons icon" :style="item.style">{{ item.icon }}</span>
                        </div>
                        <p class="name">{{ item.name }}</p>
                        <span v-if="item.external" class="material-icons icone">north_east</span>
                    </div>
                </div>
            </div>
            <div class="it2" v-if="Object.values(msfd).every((i) => Object.values(i).every((j) => j.length == 0))">
                <p><span class="material-icons">search</span><br>No results for "{{ msf }}"</p>
            </div>
            <div class="d3" v-if="Object.values(msfd).every((i) => Object.values(i).every((j) => j.length == 0))">
                <p class="t0">Web results</p>
                <div class="sd2" />
                <div class="sd3">
                    <div class="d" @click="openExternalSearch(msf)">
                        <span class="material-icons iconm">language</span>
                        <p class="name">{{ msf }}</p>
                        <span class="material-icons icone">north_east</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottomd" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import fb from '@/fb'
import store from '@/store';
import router from '@/router';
export default {
    data() {
        return {
            msf: null,
            msfd: {
                d1: {
                    Applications: [
                        {
                            name: 'Sales',
                            style: `background: linear-gradient(135deg, rgb(85, 220, 61) 0%, rgb(58, 210, 47) 100%);color: #f7f7f7;`,
                            icon: 'receipt_long',
                            route: '/sales'
                        },
                        {
                            name: 'Payments',
                            style: `background-color: #fff;
                        border: solid 0.05vw #ccc;color: #0071e3;`,
                            icon: 'account_balance',
                            route: '/payments'
                        },
                        {
                            name: 'Closure',
                            style: `background: #10171c;
                        color: #eee;`,
                            icon: 'nightlight_round',
                            route: '/closure'
                        },
                        {
                            name: 'Inventory',
                            style: `background: #9c6322cc;
                        color: #f3f3f3;`,
                            icon: 'assignment_turned_in',
                            route: '/inventory'
                        },
                        {
                            name: 'Markdowns',
                            style: `background:linear-gradient(0deg, hsla(303, 100%, 38%, 1) 0%, hsla(303, 100%, 48%, 1) 100%);
                        color: #fff;`,
                            icon: 'local_activity',
                            route: '/markdowns'
                        },
                        {
                            name: 'Requests',
                            style: `background:linear-gradient(0deg, hsla(6, 100%, 50%, 1) 0%, hsla(15, 100%, 65%, 1) 100%);
                        color: #fff;`,
                            icon: 'view_week',
                            route: '/requests'
                        },
                        {
                            name: 'Customers',
                            style: `background:#f5ecdb;
                        border: solid 0.05vw #ccc;
                        color: #834d0a;`,
                            icon: 'groups_2',
                            route: '/customers'
                        },
                        {
                            name: 'Trackboard',
                            style: `background:#fff;
                        border: solid 0.05vw #ccc;
                        color: #b0882c;`,
                            icon: 'visibility',
                            route: '/trackboard'
                        },
                        {
                            name: 'Users',
                            style: `background:#1c1919;
                        color: #efefef;`,
                            icon: 'supervised_user_circle',
                            route: '/users'
                        },
                        {
                            name: 'Audits',
                            style: `background: #fff;
                        border: solid 0.05vw #ccc;
                        color: #aaa;`,
                            icon: 'biotech',
                            route: '/audits'
                        },
                        {
                            name: 'Broadcast',
                            style: `background: #00000080;
                        color: #73f333;`,
                            icon: 'cell_tower',
                            route: '/broadcast'
                        },
                    ]
                },
                d2: {
                    Reports: [
                        {
                            name: 'Outstanding Transactions Report',
                            style: `background: linear-gradient(135deg, rgb(85, 220, 61) 0%, rgb(58, 210, 47) 100%);color: #f7f7f7;`,
                            icon: 'receipt_long',
                            route: '/sales'
                        },
                        {
                            name: 'Consolidated Sales Report',
                            style: `background: linear-gradient(135deg, rgb(85, 220, 61) 0%, rgb(58, 210, 47) 100%);color: #f7f7f7;`,
                            icon: 'receipt_long',
                            route: '/sales'
                        },
                        {
                            name: 'All Sales Reports',
                            style: `background: linear-gradient(135deg, rgb(85, 220, 61) 0%, rgb(58, 210, 47) 100%);color: #f7f7f7;`,
                            icon: 'receipt_long',
                            route: '/sales',
                            external: true
                        },
                        {
                            name: 'Consolidated Payments Report',
                            style: `background-color: #fff;
                            border: solid 0.05vw #ccc;color: #0071e3;`,
                            icon: 'account_balance',
                            route: '/payments'
                        },
                        {
                            name: 'All Payments Reports',
                            style: `background-color: #fff;
                            border: solid 0.05vw #ccc;color: #0071e3;`,
                            icon: 'account_balance',
                            route: '/payments',
                            external: true
                        },
                        {
                            name: 'Complete Closure Summary',
                            style: `background: #10171c;
                        color: #eee;`,
                            icon: 'nightlight_round',
                            route: '/closure'
                        },
                        {
                            name: 'Closure Sales Summary',
                            style: `background: #10171c;
                        color: #eee;`,
                            icon: 'nightlight_round',
                            route: '/closure'
                        },
                        {
                            name: 'Closure Tax Summary',
                            style: `background: #10171c;
                        color: #eee;`,
                            icon: 'nightlight_round',
                            route: '/closure'
                        },
                        {
                            name: 'Inventory',
                            style: `background: #9c6322cc;
                        color: #f3f3f3;`,
                            icon: 'assignment_turned_in',
                            route: '/inventory'
                        },
                        {
                            name: 'Markdowns',
                            style: `background:linear-gradient(0deg, hsla(303, 100%, 38%, 1) 0%, hsla(303, 100%, 48%, 1) 100%);
                        color: #fff;`,
                            icon: 'local_activity',
                            route: '/markdowns'
                        },
                        {
                            name: 'Requests',
                            style: `background:linear-gradient(0deg, hsla(6, 100%, 50%, 1) 0%, hsla(15, 100%, 65%, 1) 100%);
                        color: #fff;`,
                            icon: 'view_week',
                            route: '/requests'
                        },
                        {
                            name: 'Customers',
                            style: `background:#f5ecdb;
                        border: solid 0.05vw #ccc;
                        color: #834d0a;`,
                            icon: 'groups_2',
                            route: '/customers'
                        },
                        {
                            name: 'Trackboard',
                            style: `background:#fff;
                        border: solid 0.05vw #ccc;
                        color: #b0882c;`,
                            icon: 'visibility',
                            route: '/trackboard'
                        },
                        {
                            name: 'Users',
                            style: `background:#1c1919;
                        color: #efefef;`,
                            icon: 'supervised_user_circle',
                            route: '/users'
                        },
                        {
                            name: 'Audits',
                            style: `background: #fff;
                        border: solid 0.05vw #ccc;
                        color: #aaa;`,
                            icon: 'biotech',
                            route: '/audits'
                        },
                        {
                            name: 'Broadcast',
                            style: `background: #00000080;
                        color: #73f333;`,
                            icon: 'cell_tower',
                            route: '/broadcast'
                        },
                    ]
                },
                d3: {
                    Users: [
                    ],
                    Customers: [
                    ]
                },
                d4: {
                    'Recent Sales': [
                    ],
                    'Recent Payments': [
                    ]
                }
            },
            msfd_s: {
                d1: {
                    Applications: [
                        {
                            name: 'Sales',
                            style: `background: linear-gradient(135deg, rgb(85, 220, 61) 0%, rgb(58, 210, 47) 100%);color: #f7f7f7;`,
                            icon: 'receipt_long',
                            route: '/sales'
                        },
                        {
                            name: 'Payments',
                            style: `background-color: #fff;
                        border: solid 0.05vw #ccc;color: #0071e3;`,
                            icon: 'account_balance',
                            route: '/payments'
                        },
                        {
                            name: 'Closure',
                            style: `background: #10171c;
                        color: #eee;`,
                            icon: 'nightlight_round',
                            route: '/closure'
                        },
                        {
                            name: 'Inventory',
                            style: `background: #9c6322cc;
                        color: #f3f3f3;`,
                            icon: 'assignment_turned_in',
                            route: '/inventory'
                        },
                        {
                            name: 'Markdowns',
                            style: `background:linear-gradient(0deg, hsla(303, 100%, 38%, 1) 0%, hsla(303, 100%, 48%, 1) 100%);
                        color: #fff;`,
                            icon: 'local_activity',
                            route: '/markdowns'
                        },
                        {
                            name: 'Requests',
                            style: `background:linear-gradient(0deg, hsla(6, 100%, 50%, 1) 0%, hsla(15, 100%, 65%, 1) 100%);
                        color: #fff;`,
                            icon: 'view_week',
                            route: '/requests'
                        },
                        {
                            name: 'Customers',
                            style: `background:#f5ecdb;
                        border: solid 0.05vw #ccc;
                        color: #834d0a;`,
                            icon: 'groups_2',
                            route: '/customers'
                        },
                        {
                            name: 'Trackboard',
                            style: `background:#fff;
                        border: solid 0.05vw #ccc;
                        color: #b0882c;`,
                            icon: 'visibility',
                            route: '/trackboard'
                        },
                        {
                            name: 'Users',
                            style: `background:#1c1919;
                        color: #efefef;`,
                            icon: 'supervised_user_circle',
                            route: '/users'
                        },
                        {
                            name: 'Audits',
                            style: `background: #fff;
                        border: solid 0.05vw #ccc;
                        color: #aaa;`,
                            icon: 'biotech',
                            route: '/audits'
                        },
                        {
                            name: 'Broadcast',
                            style: `background: #00000080;
                        color: #73f333;`,
                            icon: 'cell_tower',
                            route: '/broadcast'
                        },
                    ]
                },
                d2: {
                    Reports: [
                        {
                            name: 'Outstanding Transactions Report',
                            style: `background: linear-gradient(135deg, rgb(85, 220, 61) 0%, rgb(58, 210, 47) 100%);color: #f7f7f7;`,
                            icon: 'receipt_long',
                            route: '/sales'
                        },
                        {
                            name: 'Consolidated Sales Report',
                            style: `background: linear-gradient(135deg, rgb(85, 220, 61) 0%, rgb(58, 210, 47) 100%);color: #f7f7f7;`,
                            icon: 'receipt_long',
                            route: '/sales'
                        },
                        {
                            name: 'All Sales Reports',
                            style: `background: linear-gradient(135deg, rgb(85, 220, 61) 0%, rgb(58, 210, 47) 100%);color: #f7f7f7;`,
                            icon: 'receipt_long',
                            route: '/sales',
                            external: true
                        },
                        {
                            name: 'Consolidated Payments Report',
                            style: `background-color: #fff;
                            border: solid 0.05vw #ccc;color: #0071e3;`,
                            icon: 'account_balance',
                            route: '/payments'
                        },
                        {
                            name: 'All Payments Reports',
                            style: `background-color: #fff;
                            border: solid 0.05vw #ccc;color: #0071e3;`,
                            icon: 'account_balance',
                            route: '/payments',
                            external: true
                        },
                        {
                            name: 'Complete Closure Summary',
                            style: `background: #10171c;
                        color: #eee;`,
                            icon: 'nightlight_round',
                            route: '/closure'
                        },
                        {
                            name: 'Closure Sales Summary',
                            style: `background: #10171c;
                        color: #eee;`,
                            icon: 'nightlight_round',
                            route: '/closure'
                        },
                        {
                            name: 'Closure Tax Summary',
                            style: `background: #10171c;
                        color: #eee;`,
                            icon: 'nightlight_round',
                            route: '/closure'
                        },
                        {
                            name: 'Inventory',
                            style: `background: #9c6322cc;
                        color: #f3f3f3;`,
                            icon: 'assignment_turned_in',
                            route: '/inventory'
                        },
                        {
                            name: 'Markdowns',
                            style: `background:linear-gradient(0deg, hsla(303, 100%, 38%, 1) 0%, hsla(303, 100%, 48%, 1) 100%);
                        color: #fff;`,
                            icon: 'local_activity',
                            route: '/markdowns'
                        },
                        {
                            name: 'Requests',
                            style: `background:linear-gradient(0deg, hsla(6, 100%, 50%, 1) 0%, hsla(15, 100%, 65%, 1) 100%);
                        color: #fff;`,
                            icon: 'view_week',
                            route: '/requests'
                        },
                        {
                            name: 'Customers',
                            style: `background:#f5ecdb;
                        border: solid 0.05vw #ccc;
                        color: #834d0a;`,
                            icon: 'groups_2',
                            route: '/customers'
                        },
                        {
                            name: 'Trackboard',
                            style: `background:#fff;
                        border: solid 0.05vw #ccc;
                        color: #b0882c;`,
                            icon: 'visibility',
                            route: '/trackboard'
                        },
                        {
                            name: 'Users',
                            style: `background:#1c1919;
                        color: #efefef;`,
                            icon: 'supervised_user_circle',
                            route: '/users'
                        },
                        {
                            name: 'Audits',
                            style: `background: #fff;
                        border: solid 0.05vw #ccc;
                        color: #aaa;`,
                            icon: 'biotech',
                            route: '/audits'
                        },
                        {
                            name: 'Broadcast',
                            style: `background: #00000080;
                        color: #73f333;`,
                            icon: 'cell_tower',
                            route: '/broadcast'
                        },
                    ]
                }
            },
            isLoading: {
                cust: false,
                user: false
            }
        };
    },
    watch: {
        msf(newData) {
            if (newData != '') {
                this.$refs.infotext1.style.height = '0vw'
                let cgl = this.getCusts(0, 3, newData);
                if (cgl.length <= 2) {
                    this.msfd.d3.Customers = cgl.map(item => {
                        return {
                            name: item.Shopname,
                            id: item.RCode,
                            phone: item.Phone,
                            func: this.route.bind(this, `/customer?cref=${item.RCode}`)
                        }
                    })
                    this.fetchCustSearch();
                } else {
                    this.msfd.d3.Customers = cgl.map(item => {
                        return {
                            name: item.Shopname,
                            id: item.RCode,
                            phone: item.Phone,
                            func: this.route.bind(this, `/customer?cref=${item.RCode}`)
                        }
                    })
                }
                let cgl2 = this.getUsers(0, 3, newData);
                if (cgl2.length <= 2) {
                    this.msfd.d3.Users = cgl2.map(item => ({
                        name: item.efname + ' ' + item.elname,
                        id: item.epcode,
                        phone: item.ephone
                    }));
                    this.fetchUsersSearch();
                } else {
                    this.msfd.d3.Users = cgl2.map(item => ({
                        name: item.efname + ' ' + item.elname,
                        id: item.epcode,
                        phone: item.ephone
                    }));
                }
                let cgl3 = this.getSales(0, 4, newData);
                if (cgl3.length <= 2) {
                    this.msfd.d4['Recent Sales'] = cgl3.map(item => ({
                        ...item,
                        id: item.ReceiptNumber,
                        sic: item.einvoice ? true : false,
                        style: `background: linear-gradient(135deg, rgb(85, 220, 61) 0%, rgb(58, 210, 47) 100%);color: #f7f7f7;`,
                        icon: 'receipt_long',
                        func: this.route.bind(this,{ name: 'Sales', params: { id: item.ReceiptNumber}})
                    }));
                } else {
                    this.msfd.d4['Recent Sales'] = cgl3.map(item => ({
                        ...item,
                        id: item.ReceiptNumber,
                        sic: item.einvoice ? true : false,
                        style: `background: linear-gradient(135deg, rgb(85, 220, 61) 0%, rgb(58, 210, 47) 100%);color: #f7f7f7;`,
                        icon: 'receipt_long',
                        func: this.route.bind(this,{ name: 'Sales', params: { id: item.ReceiptNumber}})
                    }));
                }
                let cgl4 = this.getPayments(0, 4, newData);
                if (cgl4.length <= 2) {
                    this.msfd.d4['Recent Payments'] = cgl4.map(item => ({
                        ...item,
                        id: item.id,
                        sic: item.einvoice ? true : false, style: `background-color: #fff;
                            border: solid 0.05vw #ccc;color: #0071e3;`,
                        icon: 'account_balance',
                        func: this.route.bind(this,{ name: 'Payments', params: { id: item.id}})
                    }));
                } else {
                    this.msfd.d4['Recent Payments'] = cgl4.map(item => ({
                        ...item,
                        id: item.id,
                        sic: item.einvoice ? true : false, style: `background-color: #fff;
                            border: solid 0.05vw #ccc;color: #0071e3;`,
                        icon: 'account_balance',
                        func: this.route.bind(this,{ name: 'Payments', params: { id: item.id}})
                    }));
                }
                this.msfd.d1.Applications = this.filterSearch(this.msfd_s.d1.Applications);
                this.msfd.d2.Reports = this.filterSearch(this.msfd_s.d2.Reports);
            } else {
                this.$refs.infotext1.style.height = '3vw'
                this.msfd.d3.Customers = [];
                this.msfd.d3.Users = [];
                this.msfd.d4['Recent Sales'] = [];
                this.msfd.d4['Recent Payments'] = [];
                this.msfd.d1.Applications = this.msfd_s.d1.Applications;
                this.msfd.d2.Reports = this.msfd_s.d2.Reports;
            }
        }
    },
    mounted() {
        document.getElementById('msfit').focus()
        Object.values(this.msfd).every((i) => Object.values(i).every((j) => console.log(j)))
    },
    computed: {
        ...mapGetters(['getCusts', 'getUsers', 'getSales', 'getPayments']),
    },
    methods: {
        async fetchCustSearch() {
            if (this.isLoading.cust) {
                return;
            }
            try {
                this.isLoading.cust = true;
                const searchString = this.msf.trim().toLowerCase();
                const isPureNumber = /^[0-9 ]+$/.test(searchString);
                const isAlphanumeric = /^[a-zA-Z0-9 !@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/.test(searchString);
                let query;
                if (isAlphanumeric && !isPureNumber) {
                    query = fb().db
                        .collection('CustIndex')
                        .orderBy('searchstr')
                        .orderBy('lasttxndate', 'desc')
                        .where("searchstr", ">=", searchString)
                        .where("searchstr", "<=", searchString + "\uf8ff")
                        .limit(3);
                }
                else if (isPureNumber) {
                    query = fb().db
                        .collection('CustIndex')
                        .orderBy('Phone')
                        .orderBy('lasttxndate', 'desc')
                        .where("Phone", ">=", parseInt(searchString))
                        .where("Phone", "<=", (searchString))
                        .limit(3);
                }
                const snapshot = await query.get();
                if (snapshot.empty) {
                    console.log('No more documents to fetch from Firestore.');
                    let cgl = this.getCusts(0, 3, this.msf);
                    this.msfd.d3.Customers = cgl.map(item => {
                        return {
                            name: item.Shopname,
                            id: item.RCode,
                            phone: item.Phone,
                            func: this.route.bind(this, `/customer?cref=${item.RCode}`)
                        }
                    });
                    this.isLoading.cust = false;
                    return;
                }

                const desserts = {};
                snapshot.forEach((doc) => {
                    desserts[doc.id] = doc.data();
                });

                // Update Vuex state and lcv
                store.commit('setCustIndex', desserts);
                let cgl = this.getCusts(0, 3, this.msf);
                this.msfd.d3.Customers = cgl.map(item => {
                    return {
                        name: item.Shopname,
                        id: item.RCode,
                        phone: item.Phone,
                        func: this.route.bind(this, `/customer?cref=${item.RCode}`)
                    }
                });
                this.isLoading.cust = false;
            } catch (error) {
                console.error('Error fetching documents:', error);
                this.isLoading.cust = false;
            }
        },
        async fetchUsersSearch() {
            if (this.isLoading.user) {
                return;
            }
            try {
                this.isLoading.user = true;
                const searchString = this.msf.trim().toLowerCase();
                const isPureNumber = /^[0-9 ]+$/.test(searchString);
                const isAlphanumeric = /^[a-zA-Z0-9 !@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/.test(searchString);
                let query;
                if (isAlphanumeric && !isPureNumber) {
                    query = fb().db
                        .collection('UserIndex')
                        .where("efname", ">=", searchString)
                        .where("efname", "<=", searchString + "\uf8ff")
                        .limit(3);
                }
                else if (isPureNumber) {
                    query = fb().db
                        .collection('UserIndex')
                        .where("ephone", ">=", parseInt(searchString))
                        .where("ephone", "<=", (searchString))
                        .limit(3);
                }
                const snapshot = await query.get();
                if (snapshot.empty) {
                    console.log('No more documents to fetch from Firestore.');
                    let cgl = this.getUsers(0, 3, this.msf);
                    this.msfd.d3.Users = cgl.map(item => {
                        return {
                            name: item.efname + ' ' + item.elname,
                            id: item.epcode,
                            phone: item.ephone
                        }
                    });
                    this.isLoading.user = false;
                    return;
                }

                const desserts = {};
                snapshot.forEach((doc) => {
                    desserts[doc.id] = doc.data();
                });

                // Update Vuex state and lcv
                store.commit('setUserIndex', desserts);
                let cgl = this.getUsers(0, 3, this.msf);
                this.msfd.d3.Users = cgl.map(item => {
                    return {
                        name: item.efname + ' ' + item.elname,
                        id: item.epcode,
                        phone: item.ephone
                    }
                });
                this.isLoading.user = false;
            } catch (error) {
                console.error('Error fetching documents:', error);
                this.isLoading.user = false;
            }
        },
        filterSearch(sortedArray, searchString = this.msf) {
            const filteredArray = sortedArray.filter(item => {
                const values = Object.values(item);
                for (let value of values) {
                    if (typeof value === 'string' && value.toLowerCase().includes(searchString.toLowerCase())) {
                        return true;
                    }
                    if (typeof value === 'number' && value.toString().includes(searchString)) {
                        return true;
                    }
                }
                return false;
            })
            // Get the subset based on the specified indices
            return filteredArray;
        },
        route(id) {
            router.push(id)
        }, 
        openExternalSearch(query) {
            const encodedQuery = encodeURIComponent(query); // Encode special characters for URL safety
            const url = `https://www.google.com/search?q=${encodedQuery}`;
            window.open(url, '_blank'); // Open in a new window/tab with '_blank' target
        }
    },
};
</script>
<style lang="scss" scoped>
.main {
    width: 41vw;
    height: 20vw;
    transition: 0.3s;
    position: absolute;
    z-index: 1000;
    left: 29.5vw;
    top: 15vw;
    align-self: center;
    box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.45);
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(3.5rem);
    border-radius: 1.25vw;
    border: 0.09vw solid rgb(148, 148, 148);
    display: flex;
    flex-direction: column;
    padding: 0.5vw;
    padding-bottom: 0vw;
    padding-right: 0vw;
    padding-left: 0vw;
    align-items: center;
    overflow: hidden;

    .topd {
        position: absolute;
        width: 100%;
        height: auto;
        z-index: 2000;

        .tf1 {
            font-family: sfprosbold;
            height: 3.1vw;
            padding-left: 1vw;
            padding-right: 1vw;
            font-size: 1.2vw;
            border-bottom: 0.09vw solid #b9b9b9;
            caret-color: blue;
        }

    }

    .subd {
        overflow: auto;
        display: flex;
        margin-top: 3.1vw;
        flex-direction: column;
        width: 100%;
        z-index: 1;
        padding-right: 1vw;
        padding-left: 1vw;
        gap: 1vw;

        .it1 {
            text-align: center;
            color: #0000006a;
            text-anchor: middle;
            font-size: 1.1vw;
            height: 3vw;
            width: 100%;
            overflow: hidden;
            display: grid;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            transition: 0.3s;
        }

        .it2 {
            text-align: center;
            color: #0000006a;
            text-anchor: middle;
            font-size: 1.1vw;
            height: 8vw;
            width: 100%;
            overflow: hidden;
            display: grid;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            transition: 0.3s;

            p {
                font-family: sfprosbold;
                font-size: 1.2vw;
            }

            span {
                font-size: 1.9vw;
            }
        }

        .d1 {
            display: flex;
            flex-direction: column;

            .t0 {
                font-family: sfprosbold;
                font-size: 1vw;
                color: rgb(65, 65, 65);
            }

            .sd2 {
                height: 0.07vw;
                width: 100%;
                margin-top: 0.25vw;
                background-color: #b9b9b9;
            }

            .sd3 {
                display: flex;
                gap: 1.1vw;
                margin-top: 0.75vw;
                overflow: auto;

                .app {
                    cursor: pointer;
                    position: relative;
                    transition: 0.2s;
                    width: calc(4vw * 1.185);
                    /* 4vw + (4vw * 0.25) */
                    height: calc(5.3vw * 1.185);
                    /* 5.3vw + (5.3vw * 0.25) */
                    display: grid;
                    justify-content: center;
                    align-items: center;

                    .material-icons.icon {
                        width: calc(3.85vw * 1.185);
                        /* 3.85vw + (3.85vw * 0.25) */
                        transition: 0.2s;
                        height: calc(3.85vw * 1.185);
                        /* 3.85vw + (3.85vw * 0.25) */
                        background-color: #ccc;
                        justify-content: center;
                        align-items: center;
                        display: grid;
                        border-radius: calc(0.8vw * 1.185);
                        font-size: calc(3vw * 1.185);
                        /* 3vw + (3vw * 0.25) */
                    }

                    .name {
                        font-family: sfpro;
                        color: #222;
                        font-size: calc(0.8vw * 1.1);
                        /* 0.8vw + (0.8vw * 0.25) */
                        transition: 0.2s;
                        align-self: center;
                        text-align: center;
                        margin-top: 0vw;
                    }

                    .nots {
                        transition: 0.2s;
                        font-family: sfpro;
                        color: #eee;
                        background-color: #fa0000;
                        display: grid;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: -0.4vw;
                        right: -0.4vw;
                        padding-left: 0.3vw;
                        font-size: calc(0.75vw * 1.185);
                        /* 0.75vw + (0.75vw * 0.25) */
                        padding-right: 0.3vw;
                        border-radius: 10vw;
                        padding-top: 0.15vw;
                        min-width: 1.3vw;
                        min-height: 1.3vw;
                        box-shadow: 0.07vw 0.095vw 0.25vw -0.075vw rgba(0, 0, 0, 0.75);
                    }
                }


                .app:hover {
                    position: relative;
                }
            }

            .sd3::-webkit-scrollbar {
                display: none;
            }
        }

        .d2 {
            display: flex;
            flex-direction: column;

            .t0 {
                font-family: sfprosbold;
                font-size: 1vw;
                color: rgb(65, 65, 65);
            }

            .sd2 {
                height: 0.07vw;
                width: 100%;
                margin-top: 0.25vw;
                background-color: #b9b9b9;
            }

            .sd3 {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0vw;
                margin-top: 0.75vw;
                overflow: auto;
                position: relative;

                .d {
                    width: 50%;
                    display: flex;
                    border-radius: 0.4vw;
                    cursor: pointer;
                    align-items: center;
                    padding: 0.4vw;
                    gap: 0.5vw;
                    position: relative;

                    .app {
                        cursor: pointer;
                        transition: 0.2s;
                        width: calc(4vw * .35);
                        /* 4vw + (4vw * 0.35) */
                        height: calc(5.3vw * .35);
                        /* 5.3vw + (5.3vw * 0.25) */
                        display: grid;
                        justify-content: center;
                        align-items: center;

                        .material-icons.icon {
                            width: calc(3.85vw * .35);
                            /* 3.85vw + (3.85vw * 0.25) */
                            transition: 0.2s;
                            height: calc(3.85vw * .35);
                            /* 3.85vw + (3.85vw * 0.25) */
                            background-color: #ccc;
                            justify-content: center;
                            align-items: center;
                            display: grid;
                            border-radius: calc(0.8vw * .35);
                            font-size: calc(3vw * .35);
                            /* 3vw + (3vw * 0.25) */
                        }
                    }

                    .name {
                        font-size: 1vw;
                        color: rgba(0, 0, 0, 0.925);
                    }

                    .material-icons.icone {
                        position: absolute;
                        right: 0.625vw;
                        font-size: 0.9vw;
                        color: gray;
                    }
                }

                .d:active {
                    background-color: rgba(151, 188, 232, 0.75);
                }
            }

            .sd3::-webkit-scrollbar {
                display: none;
            }
        }

        .d3 {
            display: flex;
            flex-direction: column;

            .t0 {
                font-family: sfprosbold;
                font-size: 1vw;
                color: rgb(65, 65, 65);
            }

            .sd2 {
                height: 0.07vw;
                width: 100%;
                margin-top: 0.25vw;
                background-color: #b9b9b9;
            }

            .sd3 {
                display: flex;
                flex-direction: column;
                gap: 0vw;
                margin-top: 0.75vw;
                overflow: auto;
                position: relative;

                .d {
                    width: 100%;
                    display: flex;
                    border-radius: 0.4vw;
                    cursor: pointer;
                    align-items: center;
                    padding: 0.2vw;
                    gap: 0.5vw;
                    position: relative;

                    .material-icons.iconm {
                        font-size: 1.15vw;
                        color: rgb(65, 65, 65);
                    }

                    .name {
                        font-size: 1vw;
                        color: rgba(0, 0, 0, 0.925);
                    }

                    .material-icons.icone {
                        position: absolute;
                        right: 0.625vw;
                        font-size: 0.9vw;
                        color: gray;
                    }
                }

                .d:active {
                    background-color: rgba(151, 188, 232, 0.75);
                }
            }

            .sd3::-webkit-scrollbar {
                display: none;
            }
        }

        .d4 {
            display: flex;
            flex-direction: column;

            .t0 {
                font-family: sfprosbold;
                font-size: 1vw;
                color: rgb(65, 65, 65);
            }

            .sd2 {
                height: 0.07vw;
                width: 100%;
                margin-top: 0.25vw;
                background-color: #b9b9b9;
            }

            .sd3 {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0vw;
                margin-top: 0.75vw;
                overflow: auto;
                position: relative;
                width: 100%;

                .d {
                    width: 50%;
                    display: flex;
                    border-radius: 0.4vw;
                    cursor: pointer;
                    align-items: center;
                    padding: 0.2vw;
                    gap: 0.5vw;
                    position: relative;

                    img {
                        width: auto;
                        height: 4vw;
                    }

                    .app {
                        cursor: pointer;
                        transition: 0.2s;
                        width: calc(4vw * .35);
                        /* 4vw + (4vw * 0.35) */
                        height: calc(5.3vw * .35);
                        /* 5.3vw + (5.3vw * 0.25) */
                        display: grid;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        left: 2.65vw;
                        top: 2.5vw;

                        .material-icons.icon {
                            width: calc(3.85vw * .35);
                            /* 3.85vw + (3.85vw * 0.25) */
                            transition: 0.2s;
                            height: calc(3.85vw * .35);
                            /* 3.85vw + (3.85vw * 0.25) */
                            background-color: #ccc;
                            justify-content: center;
                            align-items: center;
                            display: grid;
                            border-radius: calc(0.8vw * .35);
                            font-size: calc(3vw * .35);
                            /* 3vw + (3vw * 0.25) */
                        }
                    }

                    .infd {
                        display: flex;
                        flex-direction: column;
                        width: 67.5%;

                        .t1 {
                            font-size: 0.8vw;
                            line-height: 1vw;
                            color: rgba(0, 0, 0, 0.8);
                        }

                        .t2 {
                            font-size: 1vw;
                            line-height: 1.2vw;
                            color: rgba(0, 0, 0, 0.925);
                            width: 100%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .t3 {
                            font-size: 0.8vw;
                            line-height: 1vw;
                            margin-top: 0.3vw;
                            color: rgba(0, 0, 0, 0.75);
                        }
                    }

                    .material-icons.icone {
                        position: absolute;
                        right: 0.625vw;
                        font-size: 0.9vw;
                        color: gray;
                    }
                }

                .d:active {
                    background-color: rgba(151, 188, 232, 0.75);
                }
            }

            .sd3::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .bottomd {
        position: fixed;
        bottom: 0;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.7) 0%, transparent 100%);
        width: 100%;
        height: 0.75vw;
        z-index: 2;
    }
}</style>
  